<template>
  <el-dialog :title="title" :visible.sync="visible" class="levelEditDialog" :close-on-click-modal="false" :close-on-press-escape="false" @close="close">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" size="small" @keyup.enter.native="submit" label-width="120px">
      <el-form-item prop="code" label="等级编码">
        <el-input v-model="dataForm.code" placeholder="请填写等级编码" :maxlength="50"></el-input>
      </el-form-item>
      <el-form-item prop="name" label="等级">
        <el-input v-model="dataForm.name" placeholder="请填写等级" :maxlength="50"></el-input>
      </el-form-item>
      <el-form-item prop="num" label="分值区间">
        <div class="flexClass">
          <el-input v-model="dataForm.begin" placeholder="最小值" size="small" @change="changeMin()" :validate-event="false"></el-input>
          <span> ～ </span>
          <el-input v-model="dataForm.end" placeholder="最大值" size="small" @change="changeMax()" :validate-event="false"></el-input>
        </div>
      </el-form-item>
      <el-form-item label="包含关系">
        <el-row>
          <el-col :span="12">
            <el-checkbox v-model="dataForm.beginFlag">包含最小值</el-checkbox>
          </el-col>
          <el-col :span="12">
            <el-checkbox v-model="dataForm.endFlag">包含最大值</el-checkbox>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
    <template slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit">提交</el-button>
    </template>
  </el-dialog>
</template>
<script>
import debounce from 'lodash/debounce'
import cloneDeep from 'lodash/cloneDeep'
export default {
  name: 'levelEditDialog',
  data () {
    return {
      visible: false,
      id: null,
      pid: null,
      dataForm: {
        code: '',
        name: '',
        begin: '',
        end: '',
        beginFlag: true,
        endFlag: true
      },
      dataRule: {
        code: [{ required: true, message: '请填写等级编码', trigger: 'blur' }],
        name: [{ required: true, message: '请填写等级', trigger: 'blur' }],
        num: [
          { type: 'string', validator: (r, v, c) => this.checkNum(v, c), required: true }
        ]
      }
    }
  },
  computed: {
    title () {
      return `${this.id ? '修改' : '新增'}分值等级`
    }
  },
  methods: {
    init (pid, item) {
      if (!pid) return
      this.pid = pid
      if (item && item.id) {
        let id = this.id = item.id
        // 读取等级数据
        this.getInfo(id)
      } else {
        this.id = null
        let form = this.$options.data().dataForm
        this.$set(this, 'dataForm', form)
      }
      this.visible = true
      this.$nextTick(() => {
        this.$refs.dataForm.clearValidate()
      })
    },
    // 获取分值等级信息
    getInfo (id) {
      this.$http.get(`/level/${id}`).then(({ data: res }) => {
        let { code, msg, data } = res
        // console.log(code, msg, data)
        if (code === 0) {
          let form = cloneDeep(data)
          form.beginFlag = form.beginFlag === '1'
          form.endFlag = form.endFlag === '1'
          this.$set(this, 'dataForm', form)
        } else {
          this.$message.error(msg)
        }
      })
    },
    // 关闭窗口
    close () {
      this.visible = false
      this.$emit('reload')
    },
    // 提交数据
    submit: debounce(function () {
      if (this.dataForm.begin > this.dataForm.end) {
        this.$message.warning('最小值不能大于最大值')
        return false
      }
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) return false
        let func = this.id ? 'put' : 'post'
        let form = cloneDeep(this.dataForm)
        form.levelStandardId = this.pid
        form.beginFlag = form.beginFlag ? '1' : '0'
        form.endFlag = form.endFlag ? '1' : '0'
        this.$http[func]('/level', form).then(({ data: res }) => {
          let { code, msg } = res
          if (code !== 0) {
            this.$message.error(msg)
          } else {
            this.close()
          }
        })
      })
    }, 1000, { 'leading': true, 'trailing': false }),
    checkNum (val, callback) {
      if (this.dataForm.begin === '') {
        callback(new Error('请输入最小值'))
      } else if (this.dataForm.end === '') {
        callback(new Error('请输入最大值'))
      } else {
        callback()
      }
    },
    changeMin () { // 校验输入的值
      let newVal = this.dataForm.begin === undefined ? this.dataForm.begin : Number(this.dataForm.begin)
      console.log(newVal)
      if (newVal !== undefined) {
        if (isNaN(newVal)) {
          this.$nextTick(() => {
            this.dataForm.begin = ''
          })
        }
      }
      if (newVal <= 0) this.dataForm.begin = 0
      this.dataForm.begin = parseInt(this.dataForm.begin)
    },
    changeMax () {
      let newVal = this.dataForm.end === undefined ? this.dataForm.end : Number(this.dataForm.end)
      console.log(newVal)
      if (newVal !== undefined) {
        if (isNaN(newVal)) {
          this.$nextTick(() => {
            this.dataForm.end = ''
          })
        }
      }
      if (newVal <= 0) this.dataForm.end = 0
      this.dataForm.end = parseInt(this.dataForm.end)
    }
  }
}
</script>
<style lang="scss">
.levelEditDialog {
  .fullWidth {
    width: 100%;
  }
  .flexClass {
    display: flex;
    align-items: stretch;
    align-content: space-between;

    > span {
      margin: 0 auto;
    }

    .el-input {
      width: 46%;

      &__inner {
        text-align: center!important;
      }
    }
  }
}
</style>
